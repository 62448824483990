import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../blogposts/data/SiteConfig";
import quotes from "../../blogposts/data/quotes";
import SEO from "../components/SEO";
import ViewCounter from "../components/ViewCounter";

const QuotesPage = () => (
    <Layout>
      <SEO />
      <Helmet title={`Recommendations given to – ${config.siteTitle}`} />
      <span style={{ display: "none" }}>
        <ViewCounter id="quotes" />
      </span>
      <div className="gradient-section">
        <div className="container">
          <h2>What people say about me...</h2>
          <h5>The quotes are collated from my LinkedIn recommendations.</h5>
        </div>
        <div className="quotations">
          {quotes.map((quote) => (
            <blockquote className="quotation" key={quote.name}>
              <p>{quote.quote}</p>
              <cite>
                —{" "}
                <a
                  href={quote.profileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {quote.name}
                </a>
              </cite>
            </blockquote>
          ))}
        </div>
      </div>
    </Layout>
  );

export default QuotesPage;
